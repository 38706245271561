import { FunctionComponent, memo } from "react";

const CustomerTestimonialsContainer: FunctionComponent = memo(() => {
  return (
    <section className="self-stretch overflow-hidden flex flex-col py-16 px-64 items-center justify-start relative gap-[78px] text-left text-29xl text-black font-playfair-display lg:pl-12 lg:pr-12 lg:box-border">
      <h1 className="m-0 relative text-[inherit] tracking-[0.01em] font-bold font-inherit z-[0]">
        Что говорят наши клиенты?
      </h1>
      <img
        className="absolute my-0 mx-[!important] top-[128px] left-[1129px] w-[33.94px] h-[33.94px] z-[1]"
        alt=""
        src="/phstarfourfill3.svg"
      />
      <img
        className="absolute my-0 mx-[!important] top-[724px] left-[306px] w-[33.94px] h-[33.94px] z-[2]"
        alt=""
        src="/phstarfourfill4.svg"
      />
      <div className="relative w-[1440px] h-[518px] z-[3] text-5xl text-color-black">
        <div className="absolute top-[8px] left-[0px] w-[1440px] h-[500px] overflow-y-auto">
          <div className="absolute top-[8px] left-[181px] rounded-30xl bg-violet h-[484px] flex flex-col py-8 px-9 box-border items-start justify-start">
            <div className="flex flex-row items-center justify-start gap-[110px]">
              <div className="flex flex-row items-center justify-start gap-[60px]">
                <img
                  className="relative rounded-[50%] w-[267px] h-[267px] object-cover"
                  alt=""
                  src="/ellipse-6@2x.png"
                />
                <div className="flex flex-col items-start justify-start gap-[48px]">
                  <p className="m-0 relative tracking-[-0.02em] font-medium flex items-center w-[517px]">
                    Я хотела бы выразить свою глубокую благодарность за ваш
                    удивительный крем для лица. Я долгое время искала идеальное
                    средство, которое помогло бы мне достичь здоровой и сияющей
                    кожи, и наконец-то я его нашла среди ваших продуктов.
                  </p>
                  <div className="flex flex-col items-start justify-start gap-[4px] text-base text-color-brand">
                    <div className="relative tracking-[-0.05em] font-medium flex items-center w-[226px]">
                      Трифонова Екатерина
                    </div>
                    <div className="w-[109px] flex flex-row items-start justify-start text-xs text-color-black">
                      <div className="relative font-medium">
                        Наш постоянный клиент
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="relative w-[14.93px] h-[420px]"
                alt=""
                src="/vector.svg"
              />
            </div>
          </div>
          <img
            className="absolute top-[348.45px] left-[1302.45px] w-[32.78px] h-[32.78px]"
            alt=""
            src="/phstarfourfill7.svg"
          />
        </div>
        <div className="absolute top-[-2px] left-[165px] rounded-30xl box-border w-[1078px] h-[522px] overflow-hidden border-[2px] border-dashed border-violet" />
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[206px] left-[52px] w-[29.69px] h-[29.69px] z-[4]"
        alt=""
        src="/phstarfourfill15.svg"
      />
    </section>
  );
});

export default CustomerTestimonialsContainer;
