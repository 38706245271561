import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";
import TextContainer from "./TextContainer";

const ContactContainer: FunctionComponent = memo(() => {
  return (
    <section className="self-stretch flex flex-row py-16 px-64 items-start justify-between text-left text-5xl text-black font-playfair-display lg:pl-0 lg:pr-0 lg:box-border">
      <div className="relative w-[436px] h-[397.44px]">
        <img
          className="absolute top-[0px] left-[0px] rounded-341xl w-[270px] h-[270px] object-cover"
          alt=""
          src="/rectangle-6@2x.png"
        />
        <img
          className="absolute top-[127.44px] left-[166px] rounded-2xl w-[270px] h-[270px] object-cover"
          alt=""
          src="/rectangle-8@2x.png"
        />
        <img
          className="absolute top-[0px] left-[0px] rounded-341xl w-72 h-[285px] object-cover"
          alt=""
          src="/rectangle-7@2x.png"
        />
        <div className="absolute top-[43.44px] left-[174px] rounded-17xl bg-white w-[220px] h-14 flex flex-row items-center justify-center gap-[12px]">
          <img className="relative w-6 h-6" alt="" src="/phstarfill.svg" />
          <img className="relative w-6 h-6" alt="" src="/phstarfill1.svg" />
          <img className="relative w-6 h-6" alt="" src="/phstarfill1.svg" />
          <img className="relative w-6 h-6" alt="" src="/phstarfill1.svg" />
          <img className="relative w-6 h-6" alt="" src="/phstarfill1.svg" />
        </div>
      </div>
      <div className="flex flex-col py-0 pr-0 pl-16 items-start justify-center lg:pl-0 lg:box-border">
        <TextContainer
          healthBenefitsText="Вы будете приятно удивлены"
          naturalIngredientsText="Однажды купив наши продукты, вам уже не захочется покупать где-либо ещё."
          productImageUrl="/phstarfourfill1.svg"
          propZIndex="unset"
        />
        <div className="flex flex-row py-6 px-0 items-center justify-center gap-[16px]">
          <Link
            className="cursor-pointer [text-decoration:none] relative font-semibold text-[inherit]"
            to="/contact"
          >
            Связаться с нами
          </Link>
          <img
            className="relative w-6 h-6"
            alt=""
            src="/pharrowrightbold1.svg"
          />
        </div>
      </div>
    </section>
  );
});

export default ContactContainer;
