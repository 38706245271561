import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";
import TextContainer from "./TextContainer";

const BodyHealthContainer: FunctionComponent = memo(() => {
  return (
    <section className="self-stretch flex flex-row pt-16 px-64 pb-0 items-start justify-between relative text-left text-29xl text-black font-playfair-display lg:pl-12 lg:pr-12 lg:box-border">
      <div className="flex flex-col items-start justify-start relative z-[0]">
        <TextContainer
          healthBenefitsText="Здоровье вашего тела"
          naturalIngredientsText="Мы продаем исключительно натуральные ингредиенты и без дополнительных красителей."
          productImageUrl="/phstarfourfill5.svg"
        />
        <img
          className="absolute my-0 mx-[!important] top-[222.44px] left-[432px] w-[29.24px] h-[29.24px] z-[1]"
          alt=""
          src="/phstarfourfill6.svg"
        />
        <div className="flex flex-row py-6 px-0 items-start justify-start gap-[24px] z-[2]">
          <img
            className="relative rounded-2xl w-[133px] h-[133px] object-cover"
            alt=""
            src="/rectangle-1@2x.png"
          />
          <img
            className="relative rounded-2xl w-[133px] h-[133px] object-cover"
            alt=""
            src="/rectangle-2@2x.png"
          />
          <img
            className="relative rounded-2xl w-[133px] h-[133px] object-cover"
            alt=""
            src="/rectangle-3@2x.png"
          />
        </div>
        <div className="flex flex-row items-center justify-center gap-[16px] z-[3] text-5xl">
          <Link
            className="cursor-pointer [text-decoration:none] relative font-semibold text-[inherit]"
            to="/goods"
          >
            Наш каталог
          </Link>
          <img
            className="relative w-6 h-6"
            alt=""
            src="/pharrowrightbold.svg"
          />
        </div>
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[12.44px] left-[1304px] w-[29.24px] h-[29.24px] z-[1]"
        alt=""
        src="/phstarfourfill.svg"
      />
      <div className="relative w-[471px] h-[594px] z-[2]">
        <img
          className="absolute top-[22.56px] left-[18px] rounded-t-281xl rounded-b-none w-[435px] h-[548px] object-cover"
          alt=""
          src="/rectangle-5@2x.png"
        />
        <div className="absolute top-[-1px] left-[-1px] rounded-t-281xl rounded-b-none box-border w-[473px] h-[596px] border-[1px] border-dashed border-mediumvioletred" />
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[400.44px] left-[784px] w-[29.24px] h-[29.24px] z-[3]"
        alt=""
        src="/phstarfourfill6.svg"
      />
    </section>
  );
});

export default BodyHealthContainer;
