import { FunctionComponent, memo } from "react";
import { Input, Textarea } from "@chakra-ui/react";

const ContactForm: FunctionComponent = memo(() => {
  return (
    <div className="rounded-xl bg-white flex flex-row py-12 px-9 items-start justify-center gap-[48px] text-left text-17xl text-black font-playfair-display">
      <img
        className="relative rounded-lg w-[378.5px] h-[346px] object-cover"
        alt=""
        src="/formimage@2x.png"
      />
      <form
        className="flex flex-col items-start justify-center gap-[10px]"
        action="https://formsubmit.co/absolut.beatu@rambler.ru"
        method="post"
      >
        <div className="relative text-17xl font-semibold font-playfair-display text-black text-left">
          Свяжитесь с нами
        </div>
        <label className="cursor-pointer relative text-sm font-playfair-display text-black text-left">
          Ваше имя
        </label>
        <Input
          className="bg-[transparent]"
          variant="outline"
          width="222px"
          placeholder="Введите ваше имя"
          w="222px"
        />
        <label className="cursor-pointer relative text-sm font-playfair-display text-black text-left">
          Ваша почта
        </label>
        <Input
          className="bg-[transparent]"
          variant="outline"
          width="222px"
          backgroundColor="#fff"
          placeholder="Введите вашу электронную почту"
          w="222px"
        />
        <label className="cursor-pointer relative text-sm font-playfair-display text-black text-left">
          Ваше сообщение
        </label>
        <Textarea
          className="relative"
          variant="outline"
          w="320px"
          placeholder="Сообщение"
        />
        <button className="cursor-pointer [border:none] py-2 px-[22px] bg-violet rounded flex flex-col box-border items-center justify-start min-w-[150px]">
          <div className="relative text-xl font-medium font-playfair-display text-black text-center">
            Отправить
          </div>
        </button>
      </form>
      <div className="self-stretch relative box-border w-px border-r-[1px] border-solid border-blueviolet" />
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
        <div className="self-stretch relative font-semibold">
          Наши реквизиты
        </div>
        <div className="flex flex-col items-start justify-start text-base">
          <div className="relative">
            <p className="m-0">
              <span className="font-semibold font-playfair-display">{`Полное наименование: `}</span>
              <span>
                Общество с ограниченной ответственностью "Абсолют Бьюти"
              </span>
            </p>
            <p className="m-0">
              <span className="font-semibold font-playfair-display">
                ИНН/КПП:
              </span>
              <span> 7736316197 / 770101001</span>
            </p>
            <p className="m-0">
              <span className="font-semibold font-playfair-display">{`ОГРН: `}</span>
              <span>1187746482714</span>
            </p>
            <p className="m-0">
              <span className="font-semibold font-playfair-display">БИК:</span>
              <span> 044525225</span>
            </p>
            <p className="m-0">
              <span className="font-semibold font-playfair-display">К/С:</span>
              <span> 30101810400000000225</span>
            </p>
          </div>
          <div className="relative">
            <span className="font-semibold">Р/С:</span>
            <span> 40702810338000188523 в ПАО «Сбербанк России»</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContactForm;
