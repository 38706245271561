import { FunctionComponent } from "react";
import BeautyHeader from "../components/BeautyHeader";
import BodyHealthContainer from "../components/BodyHealthContainer";
import ContactContainer from "../components/ContactContainer";
import ContainerHeading from "../components/ContainerHeading";
import CustomerTestimonialsContainer from "../components/CustomerTestimonialsContainer";
import BeautyFooter from "../components/BeautyFooter";

const Home: FunctionComponent = () => {
  return (
    <div className="relative [background:linear-gradient(180.42deg,_#fff,_#fad0ee)] w-full overflow-hidden flex flex-col items-start justify-start">
      <BeautyHeader />
      <BodyHealthContainer />
      <ContactContainer />
      <ContainerHeading />
      <CustomerTestimonialsContainer />
      <BeautyFooter />
    </div>
  );
};

export default Home;
