import { FunctionComponent, memo } from "react";
import QualityContainer from "./QualityContainer";

const ContainerHeading: FunctionComponent = memo(() => {
  return (
    <section className="self-stretch flex flex-col py-16 px-64 items-center justify-center relative gap-[80px] text-left text-29xl text-black font-playfair-display border-t-[1px] border-solid border-blue-gray-200 border-b-[1px] lg:pl-12 lg:pr-12 lg:box-border">
      <h1 className="m-0 relative text-[inherit] tracking-[0.01em] font-bold font-inherit z-[0]">
        Наши преимущества
      </h1>
      <div className="self-stretch flex flex-row items-start justify-start gap-[64px] z-[1] text-base text-blue-gray-900 font-subtitle-m">
        <QualityContainer
          qualityControlText="/phcrownsimplefill.svg"
          qualityControlImageUrl="Качество"
          qualityControlDescription="Наши продукты проходят строгие тестирования и контроль качества, чтобы гарантировать их эффективность и безопасность."
          qualityControlImageSize="/icon--jamicons--outline--logos--plus14.svg"
          qualityControlImageUrl24x="/icon--jamicons--outline--logos--plus15.svg"
          qualityControlText16x16="/icon--jamicons--outline--logos--circle13.svg"
          qualityControlText16x16x2="/icon--iconoir--cancel4.svg"
        />
        <QualityContainer
          qualityControlText="/phbutterflyfill.svg"
          qualityControlImageUrl="Натуральность"
          qualityControlDescription="Масла и жиры, которые мы производим, извлекаются из растений и других природных источников."
          qualityControlImageSize="/icon--jamicons--outline--logos--plus16.svg"
          qualityControlImageUrl24x="/icon--jamicons--outline--logos--plus17.svg"
          qualityControlText16x16="/icon--jamicons--outline--logos--circle14.svg"
          qualityControlText16x16x2="/icon--iconoir--cancel5.svg"
        />
        <QualityContainer
          qualityControlText="/phdiamondsfourfill.svg"
          qualityControlImageUrl="Инновации"
          qualityControlDescription="Мы постоянно исследуем новые технологии и разрабатываем инновационные формулы."
          qualityControlImageSize="/icon--jamicons--outline--logos--plus16.svg"
          qualityControlImageUrl24x="/icon--jamicons--outline--logos--plus17.svg"
          qualityControlText16x16="/icon--jamicons--outline--logos--circle14.svg"
          qualityControlText16x16x2="/icon--iconoir--cancel5.svg"
        />
        <QualityContainer
          qualityControlText="/phfirefill.svg"
          qualityControlImageUrl="Устойчивость"
          qualityControlDescription="Наша компания гордится своим принципом устойчивого развития и экологической ответственности."
          qualityControlImageSize="/icon--jamicons--outline--logos--plus16.svg"
          qualityControlImageUrl24x="/icon--jamicons--outline--logos--plus17.svg"
          qualityControlText16x16="/icon--jamicons--outline--logos--circle14.svg"
          qualityControlText16x16x2="/icon--iconoir--cancel5.svg"
        />
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[222px] left-[432px] w-[29.24px] h-[29.24px] z-[2]"
        alt=""
        src="/phstarfourfill2.svg"
      />
      <img
        className="absolute my-0 mx-[!important] top-[110px] left-[1233px] w-[29.24px] h-[29.24px] z-[3]"
        alt=""
        src="/phstarfourfill10.svg"
      />
      <img
        className="absolute my-0 mx-[!important] top-[95px] left-[57px] w-[29.24px] h-[29.24px] z-[4]"
        alt=""
        src="/phstarfourfill11.svg"
      />
    </section>
  );
});

export default ContainerHeading;
