import { FunctionComponent, memo } from "react";

type QualityContainerType = {
  qualityControlText?: string;
  qualityControlImageUrl?: string;
  qualityControlDescription?: string;
  qualityControlImageSize?: string;
  qualityControlImageUrl24x?: string;
  qualityControlText16x16?: string;
  qualityControlText16x16x2?: string;
};

const QualityContainer: FunctionComponent<QualityContainerType> = memo(
  ({
    qualityControlText,
    qualityControlImageUrl,
    qualityControlDescription,
    qualityControlImageSize,
    qualityControlImageUrl24x,
    qualityControlText16x16,
    qualityControlText16x16x2,
  }) => {
    return (
      <div className="flex-1 overflow-hidden flex flex-col items-center justify-start relative text-left text-base text-blue-gray-900 font-subtitle-m">
        <img
          className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
          alt=""
          src="/picture12@2x.png"
        />
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px] z-[1]">
          <div className="rounded-81xl bg-white flex flex-row p-4 items-center justify-center">
            <img
              className="relative w-16 h-16"
              alt=""
              src={qualityControlText}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
            <div className="self-stretch relative leading-[110%] font-medium hidden">
              Category
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-center text-5xl font-playfair-display">
              <h3 className="m-0 flex-1 relative text-[inherit] leading-[110%] font-bold font-inherit">
                {qualityControlImageUrl}
              </h3>
              <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-left text-sm text-green-800 font-subtitle-m">
                <img
                  className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--circle12.svg"
                />
                <div className="flex flex-row py-0 px-1 items-center justify-center">
                  <div className="relative leading-[140%]">+2,5%</div>
                </div>
                <img
                  className="relative w-4 h-4 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--iconoir--statup8.svg"
                />
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--pencil4.svg"
              />
            </div>
          </div>
          <img
            className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
            alt=""
            src="/picture12@2x.png"
          />
          <p className="m-0 self-stretch relative leading-[140%] font-playfair-display text-center">
            {qualityControlDescription}
          </p>
          <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px] text-lg">
            <img
              className="rounded-81xl w-16 h-16 object-cover"
              alt=""
              src="/user-thumb6@2x.png"
            />
            <div className="flex-1 flex flex-col items-start justify-start">
              <div className="self-stretch relative leading-[160%]">
                Ralph Edwards
              </div>
              <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
            </div>
          </div>
        </div>
        <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-white">
          <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
              alt=""
              src={qualityControlImageSize}
            />
            <div className="flex flex-row py-0 px-4 items-center justify-center">
              <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                Button Text
              </div>
            </div>
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/icon--jamicons--outline--logos--arrowright14.svg"
            />
          </div>
          <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
              alt=""
              src={qualityControlImageUrl24x}
            />
            <div className="flex flex-row py-0 px-4 items-center justify-center">
              <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                Button Text
              </div>
            </div>
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/icon--jamicons--outline--logos--arrowright15.svg"
            />
          </div>
        </div>
        <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
            alt=""
            src={qualityControlText16x16}
          />
          <div className="flex flex-row py-0 px-1 items-center justify-center">
            <div className="relative leading-[140%]">Featured</div>
          </div>
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
            alt=""
            src={qualityControlText16x16x2}
          />
        </div>
        <img
          className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
          alt=""
          src="/user-thumb7@2x.png"
        />
      </div>
    );
  }
);

export default QualityContainer;
