import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";

const BeautyHeader: FunctionComponent = memo(() => {
  return (
    <header className="self-stretch overflow-hidden flex flex-row py-6 px-12 items-center justify-between text-left text-base text-gray-200 font-playfair-display">
      <Link
        className="cursor-pointer [text-decoration:none] relative w-[150px] h-[84.56px] bg-[url(/public/logo@3x.png)] bg-cover bg-no-repeat bg-[top]"
        to="/"
      />
      <div className="w-[500px] flex flex-row items-center justify-between">
        <Link to="/about" className="[text-decoration:none] relative font-bold text-[inherit]">
          О НАС
        </Link>
        <Link
          className="cursor-pointer [text-decoration:none] relative font-bold text-[inherit]"
          to="/goods"
        >
          ТОВАРЫ
        </Link>
        <Link
          className="cursor-pointer [text-decoration:none] relative font-bold text-[inherit]"
          to="/services"
        >
          УСЛУГИ
        </Link>
        <Link
          className="cursor-pointer [text-decoration:none] relative font-bold text-[inherit]"
          to="/contact"
        >
          КОНТАКТЫ
        </Link>
      </div>
    </header>
  );
});

export default BeautyHeader;
