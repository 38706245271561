import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import BeautyHeader from "../components/BeautyHeader";
import BeautyFooter from "../components/BeautyFooter";

const About: FunctionComponent = () => {
  return (
    <div className="relative [background:linear-gradient(180.42deg,_#fff,_#fad0ee)] w-full overflow-hidden flex flex-col items-center justify-start">
      <BeautyHeader />
      <section className="self-stretch flex flex-col pt-4 px-0 pb-0 items-center justify-start gap-[8px] text-left text-29xl text-black font-playfair-display">
        <b className="relative tracking-[0.01em]">
          Всё, что нужно знать про нас
        </b>
        <div className="relative text-13xl tracking-[0.01em] font-medium text-gray-100 text-center inline-block w-[711px]">
          Мы гордимся нашим командным духом и преданностью качеству.
        </div>
      </section>
      <section className="self-stretch flex flex-row py-24 px-12 items-start justify-center relative gap-[24px] text-left text-13xl text-black font-playfair-display">
        <img
          className="self-stretch relative rounded-2xl max-h-full w-[663px] object-cover z-[0]"
          alt=""
          src="/rectangle-9@2x.png"
        />
        <div className="self-stretch flex flex-col items-start justify-start relative gap-[24px] z-[1]">
          <p className="m-0 relative tracking-[0.01em] inline-block w-[635px] z-[0]">
            <span className="block">
              Мы специализируемся на производстве натуральных масел и жиров,
              используемых в косметических, фармацевтических и пищевых
              препаратах. Наша компания стремится к извлечению идеального
              баланса из природных ингредиентов, чтобы создать продукты, которые
              обеспечивают эффективность и заботу о коже и волосах наших
              клиентов.
            </span>
            <span className="block">&nbsp;</span>
            <span className="block">
              Наш штат насчитывает 22 высококвалифицированных специалиста,
              включая химиков, биологов и экспертов по косметической
              промышленности. Все наши сотрудники являются экспертами в своих
              областях и постоянно стремятся к инновациям и совершенствованию
              нашей продукции.
            </span>
          </p>
          <Link
            className="cursor-pointer [text-decoration:none] rounded-3xs bg-violet w-40 h-[50px] flex flex-row py-[5px] px-[26px] box-border items-center justify-center z-[1] text-center text-xl text-white"
            to="/goods"
          >
            <div className="relative">Товары</div>
          </Link>
          <img
            className="absolute my-0 mx-[!important] top-[422px] left-[0px] w-[32.78px] h-[32.78px] z-[2]"
            alt=""
            src="/phstarfourfill13.svg"
          />
          <img
            className="absolute my-0 mx-[!important] top-[324.44px] left-[399px] w-[32.78px] h-[32.78px] z-[3]"
            alt=""
            src="/phstarfourfill9.svg"
          />
        </div>
        <img
          className="absolute my-0 mx-[!important] top-[21.44px] left-[143px] w-[32.78px] h-[32.78px] z-[2]"
          alt=""
          src="/phstarfourfill14.svg"
        />
      </section>
      <BeautyFooter />
    </div>
  );
};

export default About;
