import { FunctionComponent } from "react";
import BeautyHeader from "../components/BeautyHeader";
import BeautyFooter from "../components/BeautyFooter";

const Services: FunctionComponent = () => {
  return (
    <div className="relative [background:linear-gradient(180.42deg,_#fff,_#fad0ee)] w-full h-[1600px] overflow-hidden flex flex-col items-start justify-start text-left text-29xl text-gray-2 font-playfair-display">
      <BeautyHeader />
      <div className="self-stretch flex-1 flex flex-col pt-4 px-0 pb-16 items-center justify-start relative gap-[4px]">
        <h1 className="m-0 self-stretch relative text-[inherit] tracking-[0.01em] font-bold font-inherit text-black text-center z-[0]">
          Наши услуги
        </h1>
        <div className="self-stretch flex-1 flex flex-row py-6 px-12 items-start justify-center gap-[24px] z-[1] text-13xl">
          <img
            className="self-stretch relative rounded-lg max-h-full w-[330px] object-cover"
            alt=""
            src="/rectangle-10@2x.png"
          />
          <p className="m-0 relative tracking-[0.03em] leading-[125%] inline-block w-[626px] shrink-0">
            <span className="block">
              Наша компания предлагает удобную и надежную услугу доставки, чтобы
              обеспечить нашим клиентам удобство и комфорт при покупке наших
              продуктов. Мы стремимся сделать процесс доставки максимально
              эффективным и безопасным, чтобы ваши заказы приходили вовремя и в
              отличном состоянии.
            </span>
            <span className="block">&nbsp;</span>
            <span className="block">
              Вот некоторые ключевые особенности нашей услуги доставки:
            </span>
          </p>
        </div>
        <div className="flex-1 relative text-5xl tracking-[0.03em] leading-[138.5%] inline-block w-[990px] z-[2]">
          <p className="m-0">
            <b className="font-playfair-display">
              Быстрая обработка и отправка:
            </b>
            <span>
              {" "}
              Мы гарантируем оперативную обработку и отправку всех заказов.
              После оформления заказа наша команда быстро приступает к его
              обработке, чтобы ваши товары попали на доставку в кратчайшие
              сроки.
            </span>
          </p>
          <p className="m-0">
            <b className="font-playfair-display">Гибкие варианты доставки:</b>
            <span>
              {" "}
              Мы предлагаем различные варианты доставки, чтобы соответствовать
              вашим потребностям и предпочтениям. Возможно выбрать стандартную
              доставку почтой, экспресс-доставку или курьерскую доставку, в
              зависимости от вашего местоположения и срочности получения заказа.
            </span>
          </p>
          <p className="m-0">
            <b className="font-playfair-display">Отслеживание заказа:</b>
            <span>
              {" "}
              Вы сможете отслеживать свой заказ на каждом этапе доставки. Мы
              предоставляем уникальные коды отслеживания, которые позволяют вам
              в режиме реального времени отследить местонахождение вашей посылки
              и быть в курсе ее статуса.
            </span>
          </p>
        </div>
        <img
          className="absolute my-0 mx-[!important] top-[240.44px] left-[29px] w-[32.78px] h-[32.78px] z-[3]"
          alt=""
          src="/phstarfourfill8.svg"
        />
        <img
          className="absolute my-0 mx-[!important] top-[16.44px] left-[1292px] w-[32.78px] h-[32.78px] z-[4]"
          alt=""
          src="/phstarfourfill9.svg"
        />
        <img
          className="absolute my-0 mx-[!important] top-[653.44px] left-[1231px] w-[29.39px] h-[29.39px] z-[5]"
          alt=""
          src="/phstarfourfill12.svg"
        />
      </div>
      <BeautyFooter />
    </div>
  );
};

export default Services;
