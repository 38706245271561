import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import BeautyFooter from "../components/BeautyFooter";

const Contact: FunctionComponent = () => {
  return (
    <div className="relative [background:linear-gradient(180deg,_#fff,_#fad0ee)] w-full overflow-hidden flex flex-col items-center justify-start text-left text-base text-gray-200 font-playfair-display">
      <div className="self-stretch overflow-hidden flex flex-row py-6 px-12 items-center justify-between">
        <Link
          className="cursor-pointer [text-decoration:none] relative w-[150px] h-[84.56px] bg-[url(/public/logo@3x.png)] bg-cover bg-no-repeat bg-[top]"
          to="/"
        />
        <div className="w-[500px] flex flex-row items-center justify-between">
          <Link to="/about" className="[text-decoration:none] relative font-bold text-[inherit]">
            О НАС
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative font-bold text-[inherit]"
            to="/goods"
          >
            ТОВАРЫ
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative font-bold text-[inherit]"
            to="/services"
          >
            УСЛУГИ
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative font-bold text-[inherit]"
            to="/contact"
          >
            КОНТАКТЫ
          </Link>
        </div>
      </div>
      <div className="self-stretch flex flex-col py-16 px-12 items-center justify-start gap-[10px] text-center text-blueviolet">
        <div className="self-stretch flex flex-col items-center justify-center gap-[4px]">
          <h2 className="m-0 relative text-[inherit] tracking-[0.05em] uppercase font-normal font-inherit inline-block w-[834px]">
            Контакты
          </h2>
          <h1 className="m-0 relative text-29xl font-bold font-inherit text-violet inline-block w-[834px]">
            Наш офис
          </h1>
        </div>
        <iframe
          className="[border:none] self-stretch relative h-[430px] overflow-hidden shrink-0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2243.825125160584!2d37.68803507719996!3d55.7789089899796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b5357a3c058987%3A0xbe6b05b0457be6b4!2z0JHQsNC60YPQvdC40L3RgdC60LDRjyDRg9C7LiwgNjkg0YHRgtGA0L7QtdC90LjQtSAxLCDQnNC-0YHQutCy0LAsINCg0L7RgdGB0LjRjywgMTA1MDgy!5e0!3m2!1sru!2sde!4v1688815612044!5m2!1sru!2sde"
        />
        <div className="self-stretch flex flex-row py-6 px-0 items-center justify-center gap-[48px] text-left text-black">
          <div className="flex flex-row items-center justify-start gap-[12px]">
            <img
              className="relative rounded-lg w-12 h-12 overflow-hidden shrink-0"
              alt=""
              src="/solarmappointbold.svg"
            />
            <div className="relative leading-[175%]">
              105082, город Москва, Бакунинская ул, д. 69 стр. 1, помещ. 2/а
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-[12px]">
            <img
              className="relative rounded-lg w-12 h-12"
              alt=""
              src="/solarphoneroundedbold.svg"
            />
            <div className="relative leading-[175%] inline-block w-[324px] shrink-0">
              +7 (936) 285 46-92
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-[12px]">
            <img
              className="relative rounded-lg w-12 h-12"
              alt=""
              src="/solarletterbold.svg"
            />
            <div className="relative leading-[175%]">
              absolut.beatu@rambler.ru
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
      <BeautyFooter />
    </div>
  );
};

export default Contact;
