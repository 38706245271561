import { FunctionComponent } from "react";
import BeautyHeader from "../components/BeautyHeader";
import BeautyFooter from "../components/BeautyFooter";
import { Link } from "react-router-dom";
const Goods: FunctionComponent = () => {
  return (
    <div className="relative [background:linear-gradient(180.42deg,_#fff,_#fad0ee)] w-full overflow-hidden flex flex-col items-start justify-start text-left text-29xl text-white font-playfair-display">
      <BeautyHeader />
      <section className="self-stretch flex flex-col py-4 px-0 items-center justify-start gap-[4px] text-center text-29xl text-black font-playfair-display">
        <h1 className="m-0 self-stretch relative text-[inherit] tracking-[0.01em] font-bold font-inherit">
          Наши товары
        </h1>
        <h2 className="m-0 relative text-13xl tracking-[0.01em] font-medium font-inherit text-gray-100 inline-block w-[670px]">
          Мы стремимся предоставить клиентам лучший выбор косметических
          продуктов.
        </h2>
      </section>
      <section className="self-stretch flex flex-row py-6 px-12 items-center justify-center gap-[24px] text-left text-lg text-blue-gray-900 font-subtitle-m sm:flex-col">
        <div className="flex-1 rounded-8xs bg-gray-200 box-border h-[354px] overflow-hidden flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200 sm:flex-[unset] sm:self-stretch">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium hidden">
                Кремы для лица
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                <div className="flex-1 relative leading-[110%] font-medium">
                  Косметические средства
                </div>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture1@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus1.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright1.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle1.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.17px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb1@2x.png"
          />
        </div>
        <div className="flex-1 rounded-8xs bg-gray-200 box-border h-[354px] overflow-hidden flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200 sm:flex-[unset] sm:self-stretch">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture2@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars1.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium hidden">
                Маски для лица
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                <div className="flex-1 relative leading-[110%] font-medium">
                  Ногтевой уход
                </div>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle2.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup1.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil1.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture3@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb2@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus2.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright2.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus3.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright3.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle3.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel1.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.17px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb3@2x.png"
          />
        </div>
        <div className="flex-1 rounded-8xs bg-gray-200 box-border h-[354px] overflow-hidden flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200 sm:flex-[unset] sm:self-stretch">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture4@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars2.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium hidden">
                Шампуни для волос
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                <div className="flex-1 relative leading-[110%] font-medium">
                  Чистящие средства
                </div>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle4.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup2.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil2.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture5@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb4@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus4.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright4.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus5.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright5.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle5.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel2.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.17px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb5@2x.png"
          />
        </div>
        <div className="rounded-8xs bg-gray-200 box-border w-[347px] h-[354px] overflow-hidden shrink-0 flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture6@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars3.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium hidden">
                Кондиционеры для волос
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                <div className="flex-1 relative leading-[110%] font-medium">
                  Солнцезащитные средства
                </div>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle6.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup3.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil3.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture7@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus6.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright6.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus1.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright1.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle7.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel3.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_47.5px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb1@2x.png"
          />
        </div>
      </section>
      <section className="self-stretch flex flex-row py-6 px-12 items-center justify-center gap-[24px] text-left text-lg text-blue-gray-900 font-subtitle-m sm:flex-col">
        <div className="flex-1 rounded-8xs bg-gray-200 box-border h-[354px] overflow-hidden flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200 sm:flex-[unset] sm:self-stretch">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture8@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium hidden">
                Масла для массажа
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                <div className="flex-1 relative leading-[110%] font-medium">
                  Аксессуары для макияжа
                </div>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle8.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup4.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture1@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus7.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright7.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus8.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright8.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle1.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.17px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb1@2x.png"
          />
        </div>
        <div className="flex-1 rounded-8xs bg-gray-200 box-border h-[354px] overflow-hidden flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200 sm:flex-[unset] sm:self-stretch">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture9@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars1.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium hidden">
                Масла для кожи
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                <div className="flex-1 relative leading-[110%] font-medium">
                  Парфюмерия
                </div>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle9.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup5.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil1.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture3@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb2@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus9.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright9.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus10.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright10.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle3.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel1.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.17px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb3@2x.png"
          />
        </div>
        <div className="flex-1 rounded-8xs bg-gray-200 box-border h-[354px] overflow-hidden flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200 sm:flex-[unset] sm:self-stretch">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture10@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars2.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-5xl text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium">
                Мужская косметика
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-xl">
                <b className="flex-1 relative leading-[110%] hidden">
                  Питательный крем для рук с шиповником и маслом кокоса
                </b>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle9.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup5.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil1.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture5@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb4@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus11.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright11.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus12.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright12.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle5.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel2.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.17px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb5@2x.png"
          />
        </div>
        <div className="rounded-8xs bg-gray-200 box-border w-[347px] h-[354px] overflow-hidden shrink-0 flex flex-col items-start justify-start relative border-[1px] border-solid border-blue-gray-200">
          <img
            className="self-stretch max-w-full overflow-hidden max-h-full object-cover z-[0]"
            alt=""
            src="/picture11@2x.png"
          />
          <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
            <div className="hidden flex-row items-center justify-center">
              <img
                className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--threestars3.svg"
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white font-playfair-display">
              <div className="self-stretch relative leading-[110%] font-medium hidden">
                Жиры для производства косметики
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                <div className="flex-1 relative leading-[110%] font-medium">
                  Подарочные наборы
                </div>
                <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800 font-subtitle-m">
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--circle11.svg"
                  />
                  <div className="flex flex-row py-0 px-1 items-center justify-center">
                    <div className="relative leading-[140%]">+2,5%</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/icon--iconoir--statup7.svg"
                  />
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--pencil3.svg"
                />
              </div>
            </div>
            <img
              className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
              alt=""
              src="/picture7@2x.png"
            />
            <div className="self-stretch relative leading-[160%] hidden">
              Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
              metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
              accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
              euismod integer.
            </div>
            <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
              <img
                className="rounded-81xl w-16 h-16 object-cover"
                alt=""
                src="/user-thumb@2x.png"
              />
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[160%]">
                  Ralph Edwards
                </div>
                <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
              </div>
            </div>
          </div>
          <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
            <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus13.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright13.svg"
              />
            </div>
            <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--plus8.svg"
              />
              <div className="flex flex-row py-0 px-4 items-center justify-center">
                <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                  Button Text
                </div>
              </div>
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/icon--jamicons--outline--logos--arrowright8.svg"
              />
            </div>
          </div>
          <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--jamicons--outline--logos--circle7.svg"
            />
            <div className="flex flex-row py-0 px-1 items-center justify-center">
              <div className="relative leading-[140%]">Featured</div>
            </div>
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
              alt=""
              src="/icon--iconoir--cancel3.svg"
            />
          </div>
          <img
            className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_47.5px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
            alt=""
            src="/user-thumb1@2x.png"
          />
        </div>
      </section>
      <div className="self-stretch bg-gray-200 overflow-hidden flex flex-col py-16 px-24 items-center justify-start gap-[24px]">
        <div className="flex flex-col items-center justify-start gap-[4px]">
          <b className="relative">Заинтересовали?</b>
          <div className="relative text-xl">
            Свяжитесь с нашим менеджером, чтобы узнать больше.
          </div>
        </div>
        <Link to="/contact" className="[text-decoration:none] rounded-3xs bg-violet w-[211px] h-[66px] overflow-hidden shrink-0 flex flex-row p-6 box-border items-center justify-center text-center text-[22px] text-[inherit]">
          <div className="relative leading-[18px] font-medium">Контакты</div>
        </Link>
      </div>
      <BeautyFooter />
    </div>
  );
};

export default Goods;
