import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";

const BeautyFooter: FunctionComponent = memo(() => {
  return (
    <footer className="self-stretch bg-white overflow-hidden flex flex-row py-[70px] px-12 items-center justify-center gap-[341px] text-left text-xs text-black font-subtitle-m">
      <div className="relative w-[171px] h-4">
        <div className="absolute top-[6.25%] left-[0%] font-light">
          © 2023 Все права защищены
        </div>
      </div>
      <nav className="m-0 flex flex-row items-start justify-start gap-[40px] text-left text-base text-black font-subtitle-m">
        <Link
          className="cursor-pointer [text-decoration:none] relative text-[inherit]"
          to="/"
        >
          Главная
        </Link>
        <Link to="/about" className="[text-decoration:none] relative font-bold text-[inherit]">
          О НАС
        </Link>
        <Link
          className="cursor-pointer [text-decoration:none] relative text-[inherit]"
          to="/goods"
        >
          Товары
        </Link>
        <Link
          className="cursor-pointer [text-decoration:none] relative text-[inherit]"
          to="/services"
        >
          Услуги
        </Link>
        <Link
          className="cursor-pointer [text-decoration:none] relative text-[inherit]"
          to="/contact"
        >
          Контакты
        </Link>
        <div className="relative hidden">Team</div>
      </nav>
      <div className="rounded-3xs bg-violet h-[50px] flex flex-row py-[5px] px-[26px] box-border items-center justify-center text-center text-base text-white">
        <div className="relative">+7 (936) 285 46-92</div>
      </div>
    </footer>
  );
});

export default BeautyFooter;
