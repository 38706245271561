import { FunctionComponent, memo, useMemo } from "react";
import CSS, { Property } from "csstype";

type TextContainerType = {
  healthBenefitsText?: string;
  naturalIngredientsText?: string;
  productImageUrl?: string;

  /** Style props */
  propZIndex?: Property.ZIndex;
};

const TextContainer: FunctionComponent<TextContainerType> = memo(
  ({
    healthBenefitsText,
    naturalIngredientsText,
    productImageUrl,
    propZIndex,
  }) => {
    const heroTextStyle: CSS.Properties = useMemo(() => {
      return {
        zIndex: propZIndex,
      };
    }, [propZIndex]);

    return (
      <div
        className="flex flex-col pt-24 px-0 pb-0 items-start justify-start relative gap-[8px] z-[0] text-left text-29xl text-black font-playfair-display"
        style={heroTextStyle}
      >
        <h1 className="m-0 relative text-[inherit] tracking-[0.01em] font-bold font-inherit z-[0]">
          {healthBenefitsText}
        </h1>
        <h2 className="m-0 relative text-5xl font-normal font-inherit text-gray-100 inline-block w-[654px] z-[1]">
          {naturalIngredientsText}
        </h2>
        <img
          className="absolute my-0 mx-[!important] top-[50.44px] left-[0px] w-[29.69px] h-[29.69px] z-[2]"
          alt=""
          src={productImageUrl}
        />
      </div>
    );
  }
);

export default TextContainer;
